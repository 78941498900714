import React from 'react';
import '../App.css';
import '../assets/vendors/bootstrap/css/bootstrap.min.css';
import '../assets/vendors/font-awesome/css/font-awesome.min.css';
import '../assets/vendors/slick/slick.min.css';
import '../assets/vendors/slick/slick-theme.min.css';
import '../assets/vendors/animate.min.css';
import '../assets/vendors/magnific-popup/magnific-popup.css';
import '../assets/vendors/bootstrap-datepicker/css/bootstrap-datepicker.min.css';
import '../assets/vendors/fullscreen-overlay/css/style5.css';
import '../assets/vendors/lightbox/css/lightbox.min.css';
import '../assets/fonts/font-icon/style.css';
import '../assets/css/layout.css';
import '../assets/css/elements.css';
import '../assets/css/shortcode.css';
import '../assets/css/widget.css';
import '../assets/css/extra.css';
import '../assets/css/responsive.css';
import dmcy from '../assets/images/My Books/dmcyu_.jpeg';
import coming_soon from '../assets/images/coming-soon.png';
import corruptions from '../assets/images/My Books/corruptions.png';
import green from '../assets/images/My Books/green.jpg';
import depressed from '../assets/images/My Books/depressed.jpg';
import Header_Desktop from '../components/Header_Desktop';
import Footer from '../components/Footer';

function Books() {
  return (
    <div>
      <Header_Desktop />
      <section>
    <body class="sticky-enable dark-mode">
    <div id="pagewrap" class="pagewrap">
      <div id="html-content" class="wrapper-content">
      
    <div class="page-container">
        <div class="page-content">

      <div class="section section-80">
        <div class="container">
          <div class="container"> <br></br> </div>
          <div class="about-heading"><h1 class="heading-name">My Books & Stories</h1>
                        <span class="heading-job"><span></span><span>I've published 2 fiction novels & a few short stories.
                          </span></span>
                        
                          </div>
          <div class="row mbxxl">
            <div class="col-lg-12">
              <div class="alay-sc-blog-list style-vertical">
                <div class="row">
                  <div class="col-md-6">
                    <div class="blog-item sub-item blog-center mbl">
                      <div class="img-wrap"><img src={dmcy} alt="DMCY"/></div>
                      <div class="cat-heading">My Books</div><a href="https://amazon.com/dp/B0BJZXKMM9" 
                      class="title">Darkness Me, Colorful You Vol. 1 - Grab the eBook for just $1~
                    </a>
              
                      <p class="content">
                        500 years ago, a powerful beast named "Baku" arrived in the continent known as Perpetua, aiming to destroy all of humanity. 
                        After the Goddess defeated this monster, she was warned that he would one day return in the future.
                        Meanwhile, in the present, Koka Ferrers is a young man who has more than a few sins from his unknown past, and everyone around him, even the Goddess  
                        - Valentina Jasper - who’s regarded as a legend for having once saved the world, despises him.
                      </p>
                      <p/>
                        He has one choice: search for those lost memories, stop the incoming beast Baku, redeem himself by fighting through the fire of hatred 
                        directed at him, and befriend Valentina. He may even uncover the true culprit pulling the strings behind Baku's return and fake lore brainwashing everybody.
                      <p>
                        Ultimately, this book is a YA (Young Adult) series with fantasy, action, romance and mystery as its main genres. I'm using one of the ancient 
                        7 Wonders of the World (<a href="https://en.wikipedia.org/wiki/Hanging_Gardens_of_Babylon"><u>Hanging Gardens of Babylon</u></a>) as a 
                        significant piece of the setting with strong significance to the plot. Other aspects of my book - 
                        the setting, character appearances, names, etc. - is inspired by a mix of Victorian England and the 
                        ancient Middle East, and involves magic (in the story, it's called "jiku")!
                      </p>
                      <div class="block-footer">
                        <div class="meta-infor">
                          <div class="author item"><span class="pre">By</span><a href="about.html" class="text">J.X. Fu</a></div><a href="https://seykafu.notion.site/seykafu/Darkness-Me-Colorful-You-1abf9cba146a4f16bc24f7eee9d9720e" class="date item"> Nov 1, 2022</a> <a href="https://seykafu.notion.site/seykafu/Darkness-Me-Colorful-You-1abf9cba146a4f16bc24f7eee9d9720e">Read more!</a>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-item sub-item blog-center mbl">
                      <div class="img-wrap"><img src={corruptions} alt="Corruptions"/></div>
                      <div class="cat-heading">My Books</div>
                      <a href="https://www.amazon.com/dp/B0BJ2H2N7K" 
                      class="title">Corruptions Are Best Exposed In The Autumn! Vol. 1 - Free to Read!
                      </a>
                      <p class="content">(Read on <a href="https://www.amazon.com/Corruptions-Are-Best-Exposed-Autumn-ebook/dp/B0BJ27R74M/ref=sr_1_1?crid=24XNNFKGALXA9&keywords=corruptions+are+best+exposed+in+the+autumn&qid=1665868843&qu=eyJxc2MiOiIwLjA2IiwicXNhIjoiMC4wMCIsInFzcCI6IjAuMDAifQ%3D%3D&sprefix=corruptions+are+best+exposed+in+the+autumn%2Caps%2C133&sr=8-1"
                        > <u>Kindle</u></a>, <a href="https://www.honeyfeed.fm/novels/7852"> <u>Honeyfeed</u></a>, <a href="https://www.wattpad.com/story/321436184-corruptions-are-best-exposed-in-the-autumn"> <u>Wattpad</u></a>, <a href="https://tapas.io/series/corruptions/info"><u>Tapas</u></a>!)
                        Follow a poor, struggling man and a superstar soccer woman clash in this RomCom! Life couldn't have crashed harder for the formerly rich Kai Darak, 
                        who lost everything thanks to his father's shady and corrupt business that fell under, passing on a 30 million dollar debt to his son. 
                      
                      </p>
                      <p>
                      How does one sally forth while conveying his love to the girl who's life was ruined by their said father?
                      <p>
                      No one lived with his head held higher as the prince of a wealthy conglomerate than Kai...until it was all taken away within a blink. 
                      With his father arrested for corruption one late September, Kai must build his life back up from the depths of despair, but 
                      this time as a coffee shop barista in the heart of his city. One day, a familiar girl - the captain of his country's soccer team, appears in his shop.
                    </p>
                      <p>
                      Realizing her life was ruined on multiple occasions by his father, Kai faces both love and guilt. 
                      Additionally, the head of another rich business - which his café depends on - demands that
                      he marry into their family...or else his shop is done for.
                    </p>
                      </p>
                      <div class="block-footer">
                        <div class="meta-infor">
                          <div class="author item"><span class="pre">By</span><a href="about.html" class="text">J.X. Fu</a></div><a href="" class="date item"> Sept 1, 2022</a>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-item sub-item blog-center mbl">
                      <div class="img-wrap"><img src={green} alt="Greed"/></div>
                      <div class="cat-heading">My Short Stories</div><a href="https://docs.google.com/document/d/1cwIo1jCKAuo-vtQTfikDHMCMELzgsTHAoQuMcwZhQF8/edit?usp=sharing" 
                      class="title">Green to Greed
                    </a>
              
                      <p class="content">
                        A 1000-word story about the impact of deforestation, especially in the Amazon Rainforest. Told through the lens Professor Roman Milo Santos,
                        who embraces the money earned from his special bonsai tree.
                      </p>
                      <div class="block-footer">
                        <div class="meta-infor">
                          <div class="author item"><span class="pre">By</span><a href="about.html" class="text">J.X. Fu</a></div><a href="https://seykafu.notion.site/seykafu/Darkness-Me-Colorful-You-1abf9cba146a4f16bc24f7eee9d9720e" class="date item"> Nov 1, 2022</a> <a href="https://seykafu.notion.site/seykafu/Darkness-Me-Colorful-You-1abf9cba146a4f16bc24f7eee9d9720e">Read more!</a>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-item sub-item blog-center mbl">
                      <div class="img-wrap"><img src={depressed} alt="Depression"/></div>
                      <div class="cat-heading">My Short Stories</div><a href="https://docs.google.com/document/d/1BNMSx5bfZK2nN9ebcU_YtjG5kORIeH-nhctxYPLfm90/edit?usp=sharing" 
                      class="title">An Impeccable Coin
                    </a>
              
                      <p class="content">
                        A 1000-word story about the impact of ignoring mental health, and the effect it had from one supportive housemate to another.
                      </p>
                      <div class="block-footer">
                        <div class="meta-infor">
                          <div class="author item"><span class="pre">By</span><a href="about.html" class="text">J.X. Fu</a></div><a href="https://seykafu.notion.site/seykafu/Darkness-Me-Colorful-You-1abf9cba146a4f16bc24f7eee9d9720e" class="date item"> Nov 1, 2022</a> <a href="https://seykafu.notion.site/seykafu/Darkness-Me-Colorful-You-1abf9cba146a4f16bc24f7eee9d9720e">Read more!</a>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
{/* 
                  <div class="col-md-6">
                    <div class="blog-item sub-item blog-center mbl">
                      <div class="img-wrap"><img src="./assets/images/blog/blog-square-04.jpg" alt="Alay"/></div>
                      <div class="cat-heading">My Books</div><a href="news-detail.html" class="title">Baked Oats Are Turning Oatmeal Haters into Lovers</a>
                      <p class="content">Lorem ipsum dolor sit amet, consectetur sicing elit, sed do eiusmod tempor incididunt ut.</p>
                      <div class="block-footer">
                        <div class="meta-infor">
                          <div class="author item"><span class="pre">By</span><a href="#author.html" class="text">Swintheme</a></div><a href="news-detail.html" class="date item"> Dec 29,2018</a>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="blog-item sub-item blog-center mbl">
                      <div class="img-wrap"><img src="./assets/images/blog/blog-square-05.jpg" alt="Alay"/></div>
                      <div class="cat-heading">Lifestyle</div><a href="news-detail.html" class="title">Sohla's Golden Rules for Braising</a>
                      <p class="content">Lorem ipsum dolor sit amet, consectetur sicing elit, sed do eiusmod tempor incididunt ut.</p>
                      <div class="block-footer">
                        <div class="meta-infor">
                          <div class="author item"><span class="pre">By</span><a href="#author.html" class="text">Kasey</a></div><a href="news-detail.html" class="date item"> Dec 29,2018</a>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
  */}
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>

{/* 
      <div class="section section-80">
        <div class="container">
          <div class="alay-sc-newsletter">
            <div class="newsletter-heading">
              <div class="title"><span>Sign up to receive updates on my books! </span></div>
              <div class="description">
                You'll get exclusive details on my books, including being the first to be notified when they're ready!
              </div>
            </div>
            <div class="newsletter-form">
              <form>
                <div class="form-group mb-0">
                  <input type="text" placeholder="Your Email ... " class="swin-form-control"/>
                </div>
                <button type="submit" class="btn swin-btn swin-btn-primary">Subcribe</button>
              </form>
            </div>
          </div>
        </div>
      </div>

*/}
    </div>
  </div>
  
  </div>
  </div>
  </body>
  </section>
  <Footer />
  </div>
  );
}

export default Books;